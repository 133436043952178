import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const browser = typeof window !== "undefined" && window

const UpcomingFeatures = () => {
  return (
    browser && (
      <div>
        <SEO title="Upcoming Features" />
        <h1 className="px-4 pt-4" style={{ fontSize: `24px`, fontWeight: 500 }}>
          Upcoming Features
        </h1>
        <div className="my-4 mx-3" style={{ boxShadow: "rgb(0 0 0 / 10%) 0px 0px 5px 2px", borderRadius: "15px" }}>
          <div className="my-4 mx-3 p-4 " style={{ boxShadow: "rgb(0 0 0 / 10%) 0px 0px 5px 2px", borderRadius: "15px", background: "#4a5a9b" }}>
            <h5>1. First Feature</h5>
            <p className="p-0 m-0" style={{ fontSize: "14px" }}>
              Feature Description This section explains how we may process your information. Leanpitch Technologies Private Limited, registered in Bengaluru,
              India is the organization that owns the products and services found under https://leanpitch.com . This is the privacy policy for Leanpitch and its
              products.
            </p>
          </div>
          <div className="my-4 mx-3 p-4 " style={{ boxShadow: "rgb(0 0 0 / 10%) 0px 0px 5px 2px", borderRadius: "15px", background: "#4a5a9b" }}>
            <h5>2.Second Feature</h5>

            <p className="p-0 m-0" style={{ fontSize: "14px" }}>
              We may process your personal details (Name), contact details (email address, location), phone number, purchase details (payments, vouchers),
              information about your interactions with us and any views, opinions and feedback you provide to us. “Personal information” referred in this
              document means information about an individual or from which any individual is directly or indirectly identifiable. “Process”, “Processing”,
              “Processed” means anything that is done with any Personal Information, whether by automated means, such as collection, recording, organization,
              structuring, storage, adaptation or alteration, retrieval, consultation use, disclosure by transmission, making it available, alignment,
              restriction, destruction or deletion. All this activity takes place in US and India. As mentioned in the previous section, Leanpitch Technologies
              is a company registered in Bengaluru, India. However, the server that runs Leanpitch are with Amazon Web Services (AWS) and are in US and India.
              Though the storage is in US, information related to the website is cached across the world for faster access. Even if you reside outside US and
              India, your personal information will still be transferred to the servers that reside in US. The information stored in US and India will comply
              with the privacy standards of both these countries. Leanpitch will transfer your personal data on demand to other countries where it does
              business.
            </p>
          </div>
          <div className="my-4 mx-3 p-4 " style={{ boxShadow: "rgb(0 0 0 / 10%) 0px 0px 5px 2px", borderRadius: "15px", background: "#4a5a9b" }}>
            <h5>3. Third Feature</h5>
            <p className="p-0 m-0" style={{ fontSize: "14px" }}>
              Feature Description This section explains how we may process your information. Leanpitch Technologies Private Limited, registered in Bengaluru,
              India is the organization that owns the products and services found under https://leanpitch.com . This is the privacy policy for Leanpitch and its
              products.
            </p>
          </div>
        </div>
      </div>
    )
  )
}

export default UpcomingFeatures
